<template>
  <v-container class="text-center">
    <h1>NUESTRAS MARCAS</h1>
    <v-row>
      <v-col
          v-for="item in brands"
          :key="item"
          class="d-flex child-flex"
          cols="6"
          lg="3"
      >
        <v-img
            :src="'https://assets.infotechnic.es/brands/'+item"
            contain
            class="ma-3"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "video-view",
  props: ['logged'],
  data() {
    return {
      brands: [
          'autel.svg',
          'brembo.jpg',
          'continental.svg',
          'haynes.png',
          'jbm.png',
          'kyb.png',
          'mahle.png',
          'ngk.png',
          'sntools.png',
          'textar.png',
          'tudor.webp',
      ]
    }
  },
  methods: {

  },
  created() {
    if (this.logged == 0) {
      this.$router.push('/')
    }
  },
  watch: {}
}
</script>
