<template>
    <div class="container-fluid">

        <!--BREADCRUMB-->
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/tsb"><i class="far fa-home"></i></router-link></li>
                <li class="breadcrumb-item" v-if="maker.length > 0"><router-link :to="'/tsb/'+maker">{{ maker }}</router-link></li>
                <li class="breadcrumb-item" v-if="modelID != 0"><router-link :to="'/tsb/'+maker+'/'+modelID">{{ model }}</router-link></li>
                <li class="breadcrumb-item" v-if="vehicleID != 0"><router-link :to="'/tsb/'+maker+'/'+modelID+'/'+vehicleID">{{ motor }}</router-link></li>
                <li class="breadcrumb-item" v-if="category.length > 0"><b>{{ categoryName }}</b></li>
            </ol>
        </nav>

        <!--MAKER-->
        <template v-if="$route.name == 'tsb'">
            <div class="row text-center">
                <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1 mb-3" v-for="m in makers" :key="m.id">
                    <router-link :to="'/tsb/' + m">
                        <img :src="'https://infotechnic.tecnoclub.org/logos/'+m.toLowerCase()+'.png'" alt="" class="img-thumbnail img-fluid">
                    </router-link>
                    <small style="display: block">{{ m }}</small>
                </div>
            </div>
        </template>

        <!--MODEL-->
        <template v-if="$route.name == 'models'">
            <div class="row">
                <div class="col-auto">
                    <div class="d-inline-flex">
                        <img :src="'https://infotechnic.tecnoclub.org/logos/'+maker.toLowerCase()+'.png'" alt="" class="img-fluid mr-2" style="height: 50px">
                        <h1>{{ maker }}</h1>
                    </div>
                </div>
                <div class="col-auto pt-2">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="far fa-search"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Buscar por código DTC" v-model="search">
                    </div>
                </div>
            </div>
            <hr class="mt-1">
            <div class="container" v-if="search.length == 0">
                <table class="table table-sm table-dark table-striped table-hover">
                    <thead class="bg-primary">
                    <tr>
                        <th>Modelo</th>
                        <th>Desde</th>
                        <th>Hasta</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="m in models" @click="setModel(m)" :key="m.id">
                        <td>{{ m.name }}</td>
                        <td>{{ m.yearfrom }}</td>
                        <td>{{ m.yearto }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <!--VEHICLES-->
        <template v-if="$route.name == 'vehicles'">
            <div class="d-inline-flex">
                <img :src="'https://infotechnic.tecnoclub.org/logos/'+maker.toLowerCase()+'.png'" alt="" class="img-fluid mr-2" style="height: 50px">
                <h1>{{ maker }} {{ model }}</h1>
            </div>
            <hr class="mt-1">
            <div class="container">
                <table class="table table-sm table-dark table-striped table-hover">
                    <thead class="bg-primary">
                    <tr>
                        <th>Tipo</th>
                        <th>Motor</th>
                        <th>Potencia</th>
                        <th>Cilindrada</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="v in vehicles" @click="setVehicle(v)" :key="v.id">
                        <td>{{ v.tag }}</td>
                        <td>{{ v.motor }}</td>
                        <td>{{ v.kw }} <small>KW ({{ v.cv }} CV)</small></td>
                        <td>{{ v.size }}cc</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <!--CATEGORY-->
        <template v-if="$route.name == 'categories'">
            <div class="d-inline-flex">
                <img :src="'https://infotechnic.tecnoclub.org/logos/'+maker.toLowerCase()+'.png'" alt="" class="img-fluid mr-2" style="height: 50px">
                <h1>{{ maker }} {{ model }} ({{ motor }})</h1>
            </div>
            <hr class="mt-1 mb-2">
            <div class="row px-lg-0">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2" v-for="c in categories" :key="c.id">
                    <div class="card text-white bg-primary">
                        <a href="#" @click.prevent="setCategory(c.id)">
                            <img :src="'https://infotechnic.tecnoclub.org/categories/'+c.tag+'.jpg'" class="card-img-top" alt="">
                        </a>
                        <div class="card-body py-2">
                            <h4 class="card-title m-0">{{ c.name }}</h4>
                        </div>
                    </div>
                </div>
            </div>

        </template>

        <!--BULLETIN-->
        <template v-if="$route.name == 'viewer'">
            <div class="d-inline-flex">
                <img :src="'https://infotechnic.tecnoclub.org/logos/'+maker.toLowerCase()+'.png'" alt="" class="img-fluid mr-2" style="height: 50px">
                <h1>{{ maker }} {{ model }} {{ motor }}</h1>
<!--                <h5 class="ml-1 align-self-end">-->
<!--                    <span class="badge badge-primary"><i class="fas fa-car"></i> {{ vehicleID }}</span>-->
<!--                </h5>-->
            </div>
            <h2 class="card-subtitle">{{ categoryName }}</h2>
            <hr class="mt-1">
            <div class="row">
                <div class="col-12 col-md-4">
                    <label class="mb-1 d-block d-md-none">Selecciona un boletín:</label>
                    <select class="custom-select custom-select-sm d-block d-md-none mb-4 bg-primary text-white" v-model="bulletin">
                        <option v-for="(b, index) in filtered" :value="index" :key="index">{{ b.title }}</option>
                    </select>
                    <select class="form-control d-none d-md-block" :size="filtered.length" v-model="bulletin">
                        <option v-for="(b, index) in filtered" :value="index" :key="index">{{ b.title }}</option>
                    </select>
                </div>
                <div class="col-12 col-md-8">
                    <template v-if="filtered.length > 0">
                        <bulletin :bulletin="filtered[bulletin]" v-if="bulletins.length > 0"></bulletin>
                        <br>
                        <p style="color:white">{{ filtered[bulletin].bulletin_id }}</p>
                    </template>
                </div>
            </div>
        </template>

        <!--SEARCH-->
        <template v-if="search.length > 0">
            <h2 class="mb-3">Busqueda del código {{ search }}</h2>
            <div class="row" v-if="dtcs.length > 0">
                <div class="col-12">
                    <table class="table table-sm table-dark table-striped table-hover">
                        <thead class="bg-primary">
                        <tr>
                          <th>Fabricante</th>
                          <th>DTC</th>
                          <th>Descripción</th>
                          <th>Razón</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="d in dtcs" :key="d.id">
                          <td>{{ d.make }}</td>
                          <td>{{ d.dtc }}</td>
                          <td>{{ d.description }}</td>
                          <td>{{ d.reason }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <label class="mb-1 d-block d-md-none">Selecciona un boletín:</label>
                    <select class="custom-select custom-select-sm d-block d-md-none mb-4 bg-primary text-white" v-model="bulletin">
                        <option v-for="(b, index) in bulletins" :value="index" :key="index">{{ b.title }}</option>
                    </select>
                    <select class="form-control d-none d-md-block" :size="bulletins.length" v-model="bulletin">
                        <option v-for="(b, index) in bulletins" :value="index" :key="index">{{ b.title }}</option>
                    </select>
                </div>
                <div class="col-12 col-md-8">
                    <template v-if="bulletins.length > 0">
                        <bulletin :bulletin="bulletins[bulletin]" v-if="bulletins.length > 0"></bulletin>
                        <br>
                        <p style="color:white">{{ bulletins[bulletin].bulletin_id }}</p>
                    </template>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
    import settings from "../settings";
    import axios from 'axios';
    import bulletin from '../components/BulletinViewer.vue';
    let API_URL = settings.API_URL;
    export default {
        name: "bulletins-view",
        props: ['logged'],
        components:{bulletin},
        data() {
            return {
                makers: [],
                models: [],
                vehicles: [],
                bulletins: {},
                categories: [
                    { id:1, name:'Airbag', tag:'airbag' },
                    { id:2, name:'Alumbrado', tag:'lights' },
                    { id:3, name:'Carrocería', tag:'exterior' },
                    { id:4, name:'Cierre', tag:'doorlock' },
                    { id:5, name:'Climatización', tag:'climate' },
                    { id:6, name:'Confort', tag:'interior' },
                    { id:7, name:'Dirección', tag:'steer' },
                    { id:8, name:'Frenos', tag:'brakes' },
                    { id:9, name:'Inmovilizador', tag:'key' },
                    { id:10, name:'Mantenimiento', tag:'revision' },
                    { id:11, name:'Motor', tag:'engine' },
                    { id:12, name:'Tracción', tag:'tires' },
                    { id:13, name:'Suspensión', tag:'suspension' },
                    { id:14, name:'Transmisión', tag:'trans' },
                    { id:15, name:'Híbrido/Eléctrico', tag:'electric' }
                ],
                category: '',
                maker: '',
                search: '',
                model: '',
                motor: '',
                modelID:0,
                vehicleID:0,
                vehicle_id: 0,
                bulletin: 0,
                dtcs: {}
            }
        },
        created() {
            if(this.logged == 0){
                this.$router.push('/')
            }
            this.listMakers();
            this.checkRoute(this.$route);
        },
        computed: {
            filtered() {
                if (this.bulletins.length > 0) {
                    return this.bulletins.filter(item => {
                        return item.category == this.category;
                    })
                }else{
                    return [];
                }

            },
            categoryName() {
                return this.categories.filter(item => {
                    return item.id == this.category;
                })[0].name;

            }
        },
        methods: {
            listMakers() {
                axios.post(API_URL + 'listMakers.php', {
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.$emit('logout')
                    } else {
                        this.makers = response.data.list
                    }
                });
            },
            listModels() {
                axios.post(API_URL + 'listModels.php', {
                    maker: this.maker,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.logout()
                    } else {
                        this.models = response.data.list
                    }
                });
            },
            listVehicles() {
                axios.post(API_URL + 'listVehicles.php',{
                    model: this.modelID,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.logout()
                    } else {
                        this.vehicles = response.data.list
                    }
                });
            },
            listBulletins() {
                axios.post(API_URL + 'listBulletins.php',{
                    vehicleID: this.vehicleID,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.logout()
                    } else {
                        this.bulletins = response.data.list
                    }
                });
            },
            newSearch(str) {
                axios.post(API_URL + 'searchDTC.php',{
                    maker: this.maker,
                    search: str,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.logout()
                    } else {
                        this.bulletins = response.data.list
                        this.dtcs = response.data.dtcs
                    }
                });
            },
            countCategory(c){
                let temp = this.bulletins.filter(item => {
                    return item.category == c;
                })
                return temp.length;
            },
            checkRoute(to) {
                //Clean the data
                this.maker = '';
                this.modelID = 0;
                this.vehicleID = 0;
                this.category = '';
                this.search = '';
                this.models = [];
                this.vehicles = [];
                this.bulletins = [];

                //Get URL params and list data
                if (to.name == 'models'){
                    this.maker = to.params.maker;
                    this.listModels();
                }else if (to.name == 'vehicles') {
                    this.maker = to.params.maker;
                    this.modelID = to.params.model;
                    this.listVehicles();
                }else if (to.name == 'categories') {
                    this.maker = to.params.maker;
                    this.modelID = to.params.model;
                    this.vehicleID = to.params.vehicle;
                    this.listBulletins();
                }else if (to.name == 'viewer') {
                    this.maker = to.params.maker;
                    this.modelID = to.params.model;
                    this.vehicleID = to.params.vehicle;
                    this.category = to.params.category;
                    this.listBulletins();
                }
            },
            setModel(m) {
                this.$router.push(this.maker+'/'+m.id)
                this.model = m.name
            },
            setVehicle(v) {
                this.$router.push(this.modelID+'/'+v.id)
                this.motor = v.motor
            },
            setCategory(c) {
                this.$router.push(this.vehicleID+'/'+c)
            },
        },
        watch: {
            '$route'(to) {
                this.checkRoute(to);
            },
            search: function (val) {
              let current = val
              setTimeout(() => {
                if (this.search == current){
                  this.newSearch(current)
                }
              }, 1000); // 1/4 sec delay
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../theme';
    @import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style scoped>
    table td{
        cursor: pointer;
    }
</style>

