<template>
    <div class="card">
        <div class="card-body no-copy">
            <!--<span class="badge badge-danger"><i class="fas fa-tag"></i> {{ bulletin.bulletin_id }}</span>-->
            <h3 class="card-title" v-text="bulletin.title"></h3>
            <h4 class="card-subtitle mb-2 text-muted" v-text="bulletin.tags"></h4>
            <hr>
            <h5>Problema</h5><p v-html="bulletin.trouble"></p>
            <h5>Causa</h5><p v-html="bulletin.reason"></p>
            <h5>Solución</h5><p v-html="bulletin.solution"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bulletin-viewer",
        props: ['bulletin']
    }
</script>

<style>
    .card-body img {
        max-width: 400px;
    }
    .no-copy {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
</style>