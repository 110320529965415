<template>
  <div class="container-fluid">
    <v-dialog
        v-model="viewer"
        scrollable="false"
        width="auto"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Video de muestra
        </v-btn>
      </template>

      <v-card class="p-0" max-width="800">
        <v-card-title class="primary white--text text-h5">
          Video de muestra
        </v-card-title>

        <v-card-text class="p-0 overflow-hidden primary">
          <video width="100%" height="auto" controls autoplay>
            <source :src="url" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "video-view",
  props: ['logged'],
  data() {
    return {
      viewer: true,
      title: '',
      url: 'https://next.tecnoclub.org/docs/example.mp4'
    }
  },
  methods: {

  },
  created() {
    if (this.logged == 0) {
      this.$router.push('/')
    }
  },
  watch: {}
}
</script>
