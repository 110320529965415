<template>
  <div class="container-fluid">
    <h1>Cursos por vehículo</h1>
    En construcción
  </div>
</template>

<script>
import axios from 'axios';
import settings from "../settings";

let API_URL = settings.API_URL;
let PARTNER = settings.PARTNER;
export default {
  name: "vehicle-lessons-view",
  props: ['logged'],
  data() {
    return {
      sections: [

      ],
      dir: '',
      files: [],
      viewer: false,
      title: '',
      url: ''
    }
  },
  methods: {
    getDir(d) {
      this.dir = d;
      axios.post(API_URL + 'scandir.php', {
        dir: '../../' + PARTNER + '/docs/formacion/' + d,
        username: sessionStorage.username,
        token: sessionStorage.token
      }).then(response => {
        if (!response.data.auth) {
          this.$emit('logout')
        } else {
          this.files = response.data.list
        }
      });
    },
    goBack() {
      this.files = [];
      this.dir = '';
    },
    goTo(title, url) {
      // window.open('https://'+PARTNER+'.tecnoclub.org/docs/formacion/'+url);
      this.title = title
      this.url = 'https://' + PARTNER + '.tecnoclub.org/docs/formacion/' + url
      this.viewer = true
    },
  },
  created() {
    if (this.logged == 0) {
      this.$router.push('/')
    }
  },
  watch: {}
}
</script>
