import Vue from 'vue'
import Vuex from 'vuex'
import settings from "@/settings";
import themes from "@/themes";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        partner: settings.PARTNER,
        theme: themes[settings.PARTNER]
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
