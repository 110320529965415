export default {
    next: {
        primary: '#eb6c16',
        dark: true,
        video: true,
    },
    andel: {
        primary: '#e7521f',
        dark: false,
        video: false,
    },
    bestdrive: {
        primary: '#eca02d',
        dark: true,
        video: false,
    }
}